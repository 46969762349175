import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { IApiPaths, setApiPaths } from './shared/lib/apiConstants';

const VeilederStart = lazy(() => import('./veileder/VeilederStart'));
const Veileder = lazy(() => import('./veileder/Veileder'));
const VeilederOppsummering = lazy(() => import('./veileder/VeilederOppsummering'));
const SendEpostDigitalSignering = lazy(() => import('./kunder/Sendepost/SendEpostDigitalSignering'));

export interface IAppProps {
    basename: string;
    apiPaths: IApiPaths;
    isExternalApp: boolean;
}
const toastOptions = {
    className: '',
    duration: 7000,
    style: {
        padding: '16px',
        color: '#000',
        backgroundColor: '#F9CBCB',
    },
};

const ExternalApp = (props: IAppProps) => {
    setApiPaths(props.apiPaths);
    window.isExternalApp = true;

    return (
        <Suspense
            fallback={
                <div className="container">
                    <h1>Vennligst vent...</h1>
                </div>
            }
        >
            <Router basename={props.basename}>
                <RouteApp />
            </Router>

            <Toaster position="top-center" reverseOrder toastOptions={toastOptions} />
        </Suspense>
    );
};

export const RouteApp = () => {
    return (
        <Routes>
            <Route path="/kunder/:kundeId/veileder/:veilederId/" element={<VeilederStart />} />
            <Route path="/kunder/:kundeId/veileder/:veilederId/steg" element={<Veileder />} />
            <Route path="/kunder/:kundeId/veileder/:veilederId/oppsummering" element={<VeilederOppsummering />} />
            <Route path="/kunder/send-epost-signering/:kundeId/:dokumentId/:tittel" element={<SendEpostDigitalSignering />} />
        </Routes>
    );
};

export default ExternalApp;
